/* fade-transform */
.fade-transform-leave-active[data-v-038e7047],
.fade-transform-enter-active[data-v-038e7047] {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.fade-transform-enter[data-v-038e7047] {
  opacity: 0;
  overflow-x: hidden;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}
.fade-transform-leave-to[data-v-038e7047] {
  opacity: 0;
  overflow-x: hidden;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}
.wrap-container[data-v-038e7047] {
  height: 100%;
}
.wrap-container .wrap-main[data-v-038e7047] {
    padding: 10px;
}
.main-scrollbar[data-v-038e7047] {
  height: 100%;
}
.main-scrollbar[data-v-038e7047] .is-horizontal {
    display: none;
}
.main-scrollbar[data-v-038e7047] .el-scrollbar__wrap {
    overflow: auto;
    overflow-x: hidden;
}
.main-scrollbar[data-v-038e7047] .el-scrollbar__view {
    height: 100%;
}
