.custom-left-menu[data-v-93207d6e] .aside-logo {
  margin-bottom: 2px;
}
.custom-left-menu[data-v-93207d6e] .aside-logo img {
    height: 48px;
    max-width: 50px;
}
.custom-left-menu[data-v-93207d6e] .aside-logo .aside-title {
    font-size: var(--rootFontSize2);
}
.custom-left-menu[data-v-93207d6e] .el-menu-item {
  font-size: var(--rootFontSize);
}
.custom-left-menu[data-v-93207d6e] .el-submenu__title {
  font-size: var(--rootFontSize);
}
.custom-left-menu[data-v-93207d6e] .el-menu--collapse .el-menu-item {
  color: inherit !important;
}
.custom-left-menu[data-v-93207d6e] .el-menu--collapse .el-menu-item i {
    color: inherit !important;
}
