.wrap-tags {
  width: 100%;
  height: 40px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.wrap-tags .tags-box {
    width: 100%;
    height: 40px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-bottom: 1px solid #499ddb;
}
.wrap-tags .tags-list {
    position: absolute;
    padding: 2px 10px;
    overflow: visible;
    white-space: nowrap;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
}
.wrap-tags .tag-item {
    position: relative;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin: 2px 4px 2px 0;
    padding: 0 10px;
    border-radius: 3px;
    vertical-align: middle;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
}
.wrap-tags .tag-text {
    margin-left: 8px;
}
.wrap-tags .no-close {
    margin-right: 8px;
}
.wrap-tags .tag-close {
    margin-left: 8px;
}
.wrap-tags .tag-item:hover {
    opacity: 0.85;
}
.wrap-tags .contextmenu {
    left: 0px;
    top: 0px;
}
.tag-item.is-active {
  background-color: var(--mainThemeColor) !important;
  color: #ffffff;
}
.tags-box {
  border-bottom: 1px solid var(--mainThemeColor) !important;
}
.nav-tags {
  background-color: var(--mainThemeColor) !important;
  border-color: var(--mainThemeColor) !important;
}
