.aside-menu {
  overflow: hidden !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.aside-menu .horizontal-collapse-transition {
    -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}
.aside-menu .scrollbar-wrapper {
    overflow-x: hidden !important;
    height: calc(100vh - 60px) !important;
    background-color: #ffffff !important;
    margin-bottom: 0 !important;
}
.aside-menu .el-scrollbar__bar.is-vertical {
    right: 0px;
}
.aside-menu .is-horizontal {
    display: none;
}
.aside-logo {
  background-color: var(--secondThemeColor) !important;
}
.el-submenu__title:hover,
.el-menu-item:hover {
  background-color: var(--mainThemeColor) !important;
  color: white !important;
}
.el-submenu__title:hover i,
  .el-menu-item:hover i {
    color: white !important;
}
.el-menu-item.is-active {
  background-color: var(--mainThemeColor) !important;
  color: white !important;
}
.el-menu-item.is-active .el-submenu__title {
    color: white !important;
}
.el-menu-item.is-active .el-submenu__title i {
      color: white !important;
}
.submenu-title .is-active {
  color: white !important;
}
.submenu-title .is-active .el-submenu__title {
    color: white !important;
}
.submenu-title .is-active .el-submenu__title i {
      color: white !important;
}
.el-submenu__title {
  color: var(--fourThemeColor) !important;
}
.el-submenu__title i {
    color: var(--fourThemeColor) !important;
}
.el-menu-item {
  color: var(--fourThemeColor) !important;
}
.submenu-title .is-active {
  background-color: var(--mainThemeColor) !important;
}
