@charset "UTF-8";
.aside-logo[data-v-3082141c] {
  width: 100%;
  height: 60px;
  background-color: #008df7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.aside-logo img[data-v-3082141c] {
    width: 54px;
    height: 54px;
    margin: 0 auto;
    -o-object-fit: contain;
       object-fit: contain;
}
.aside-logo .aside-title[data-v-3082141c] {
    width: 100%;
    height: 100%;
    line-height: 60px;
    padding-left: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    text-align: justify;
}
.aside-logo .aside-title[data-v-3082141c]:after {
    content: '';
    width: 100%;
    display: inline-block;
}
.menu-wrap[data-v-3082141c] {
  min-height: calc(100vh - 60px);
  background-color: #ffffff;
}
.collapse-icon[data-v-3082141c] {
  font-size: 26px;
  width: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  background: transparent;
  position: fixed;
  top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  color: var(--mainThemeColor);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
}

/*隐藏文字*/
[data-v-3082141c] .el-menu--collapse .el-submenu__title span {
  display: none;
}

/*隐藏 > */
[data-v-3082141c] .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
